import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_filter_criteria = _resolveComponent("filter-criteria")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_advanced_filters = _resolveComponent("advanced-filters")!

  return (_openBlock(), _createBlock(_component_advanced_filters, {
    "filter-default": _ctx.filterDefault,
    "filter-dirty": _ctx.filterDirty,
    onReset: _ctx.reset,
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.countStatus')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_b_form_checkbox, {
            modelValue: _ctx.modelValue.active,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.active) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.active')
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_b_form_checkbox, {
            modelValue: _ctx.modelValue.completedAwaitingReview,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.completedAwaitingReview) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.completedAndAwaitingReview')
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_b_form_checkbox, {
            modelValue: _ctx.modelValue.closedReports,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.closedReports) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.closedReports')
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_b_form_checkbox, {
            modelValue: _ctx.modelValue.pastDue,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue.pastDue) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.pastDue')
          }, null, 8, ["modelValue", "label"])
        ]),
        _: 1
      }),
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.dateRange')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_date_input, {
            modelValue: _ctx.modelValue.fromDate,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelValue.fromDate) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.dateFrom'),
            clearable: _ctx.modelValue.fromDate !== null
          }, null, 8, ["modelValue", "label", "clearable"]),
          _createVNode(_component_date_input, {
            modelValue: _ctx.modelValue.toDate,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modelValue.toDate) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.dateTo'),
            clearable: _ctx.modelValue.toDate !== null
          }, null, 8, ["modelValue", "label", "clearable"])
        ]),
        _: 1
      }),
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.locations')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_form_multi_select, {
            modelValue: _ctx.modelValue.location,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modelValue.location) = $event)),
            "search-by": "name",
            data: _ctx.locations
          }, null, 8, ["modelValue", "data"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["filter-default", "filter-dirty", "onReset", "onSubmit"]))
}