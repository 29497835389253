import Location from '@/domain/Location';
import SearchFilterBase from '@/dtos/filters/SearchFilterBase';

export default class CycleCountSearchFilter extends SearchFilterBase {
    constructor(init?: Partial<CycleCountSearchFilter>) {
        super(init?.recordCount);
        if (init) Object.assign(this, init);
    }

    active = true;

    completedAwaitingReview = true;

    location: Location[] = [];

    fromDate: Date | null = null;

    toDate: Date | null = null;

    closedReports = false;

    pastDue = true;

    get toDTO(): CycleCountSearchFilterDTO {
        return new CycleCountSearchFilterDTO(this);
    }

    public hydrate(val: Partial<CycleCountSearchFilter>) {
        super.hydrate(val);
        this.fromDate = val.fromDate ? new Date(val.fromDate) : null;
        this.toDate = val.toDate ? new Date(val.toDate) : null;
    }
}

export class CycleCountSearchFilterDTO extends SearchFilterBase {
    constructor(init: CycleCountSearchFilter) {
        super(init.recordCount);
        this.completedAwaitingReview = init.completedAwaitingReview;
        if (init.fromDate) this.fromDate = init.fromDate;
        if (init.toDate) this.toDate = init.toDate;
        this.closedReports = init.closedReports;
        this.locationIdList = init.location.map((l) => l.id);
        this.pastDue = init.pastDue;
        this.active = init.active;
    }

    active = false;

    completedAwaitingReview = false;

    locationIdList: number[] = [];

    fromDate?: Date;

    toDate?: Date;

    closedReports = false;

    pastDue = false;
}
