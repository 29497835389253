
import {
    computed, defineComponent, h, onBeforeMount,
} from 'vue';
import Screen from '@/components/layout/Screen.vue';
import CycleCountReportService from '@/services/CycleCountReportService';
import CycleCountReport, { CycleCountReportStatus } from '@/domain/cyclecount/CycleCountReport';
import router from '@/router';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import { TableSortObject } from '@/components/bootstrap-library/table/BTable/composables/useTableSort';
import CycleCountSearchFilter from '@/dtos/filters/CycleCountSearchFilter';
import { useFilterSearch } from '@/composable/useFilterSearch';
import { formatDateUI } from '@/functions/date';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import { TableDefinition } from '@/types';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import CycleCountAdvancedFilters from '@/components/filters/CycleCountAdvancedFilters.vue';
import WarningIcon from '@/components/WarningIcon.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type TableData = {
    reportId: number;
    locationName: string;
    locationType: string;
    dueDate: Date;
    totalItems: number;
    totalCounted: number;
    percentComplete: number;
    adjustmentAvailableCount: number;
    cycleCountReport: CycleCountReport;
    status: string;
    closedDate?: Date;
}

export default defineComponent({
    name: 'cycle-count-report-list',
    components: {
        BAdvancedTable,
        BDropdownItem,
        BDropdown,
        Screen,
    },
    setup() {
        const cycleCountReportService = new CycleCountReportService();

        const {
            filterState,
            submit: searchReports,
            state,
            reset: resetFilter,
            isFilterDirty,
            isFilterDefault,
            recordCountWarning,
        } = useFilterSearch<CycleCountReport, CycleCountSearchFilter>({
            searchFunction: cycleCountReportService.getCycleCountReportsByFilter.bind(cycleCountReportService),
            tableKey: 'cycleCountReportList',
            defaultFilters: new CycleCountSearchFilter(),
        });

        onBeforeMount(async () => {
            await searchReports();
        });

        function mapToTableData(reports: Array<CycleCountReport>): Array<TableData> {
            return reports.map((data) => ({
                reportId: data.id,
                locationName: data.location.name,
                locationType: data.location.type.description,
                dueDate: data.dueDate,
                totalItems: data.totalItems,
                totalCounted: data.totalCountedItems,
                percentComplete: data.percentComplete,
                adjustmentAvailableCount: data.adjustmentsAvailable,
                cycleCountReport: data,
                status: data.statusText,
                closedDate: data.closedDate,
            }));
        }

        const tableData = computed((): Array<TableData> => mapToTableData(state.resultSet));

        const defaultSort: Array<TableSortObject> = [
            { key: 'locationName', direction: 'descending' },
            { key: 'dueDate', direction: 'descending' },
        ];

        const table = computed((): TableDefinition<TableData> => ({
            items: tableData.value,
            key: 'cycleCountList',
            name: getTitleCaseTranslation('core.common.cycleCountReview'),
            columnDefinition: [
                { key: 'reportId', label: getTitleCaseTranslation('core.domain.id'), searchable: true },
                { key: 'locationName', label: getTitleCaseTranslation('core.domain.location'), searchable: true },
                { key: 'locationType', label: getTitleCaseTranslation('core.domain.locationType'), searchable: true },
                { key: 'dueDate', label: getTitleCaseTranslation('core.domain.dueDate'), searchable: true },
                { key: 'totalItems', label: getTitleCaseTranslation('core.domain.totalItems'), searchable: true },
                { key: 'totalCounted', label: getTitleCaseTranslation('core.domain.totalCounted'), searchable: true },
                { key: 'percentComplete', label: getTitleCaseTranslation('core.domain.percentComplete') },
                {
                    key: 'adjustmentAvailableCount',
                    label: getTitleCaseTranslation('core.domain.adjustmentAvailableCount'),
                    searchable: true,
                },
                { key: 'closedDate', label: getTitleCaseTranslation('core.domain.closedDate'), searchable: true },
                { key: 'status', label: getTitleCaseTranslation('core.domain.status'), searchable: true },
            ],
            headIcon: () => h(WarningIcon, {
                message: recordCountWarning.value,
            }),
            sidePane: () => h(CycleCountAdvancedFilters, {
                onSubmit: searchReports,
                onReset: resetFilter,
                modelValue: filterState.value,
                disabled: state.loading,
                filterDirty: isFilterDirty.value,
                filterDefault: isFilterDefault.value,
            }),
        }));

        function startCycleCount(data: TableData) {
            // this is only used in master data, but this should probably push dynamically instead
            router.push({
                name: MasterDataRouteTypes.CYCLE_COUNT_REPORT.REPORT,
                params: { reportId: data.reportId },
            });
        }

        return {
            state,
            startCycleCount,
            defaultSort,
            formatDateUI,
            CycleCountReportStatus,
            table,
            getTitleCaseTranslation,
        };
    },
});

