import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76d11828"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_advanced_table, {
        "table-array": [_ctx.table],
        "sticky-header": "calc(100vh - 105px)",
        loading: _ctx.state.loading,
        "dynamic-columns": "",
        "side-pane-options": {
                hideHeader: true
            }
      }, {
        "cell(percentComplete)": _withCtx((row) => [
          _createElementVNode("span", null, _toDisplayString(row.item.percentComplete) + "%", 1)
        ]),
        "cell(dueDate)": _withCtx((row) => [
          _createElementVNode("span", null, _toDisplayString(_ctx.formatDateUI(row.item.dueDate)), 1)
        ]),
        "cell(closedDate)": _withCtx((row) => [
          _createElementVNode("span", null, _toDisplayString(row.item.closedDate ? _ctx.formatDateUI(row.item.closedDate) : '-'), 1)
        ]),
        "cell(adjustmentAvailableCount)": _withCtx((row) => [
          (row.item.cycleCountReport.status === _ctx.CycleCountReportStatus.Open)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(row.item.adjustmentAvailableCount), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, "-"))
        ]),
        "cell(action)": _withCtx((row) => [
          _createVNode(_component_b_dropdown, {
            "no-caret": "",
            "close-on-click": ""
          }, {
            "button-content": _withCtx(() => [
              _createVNode(_component_faicon, { icon: "ellipsis-h" })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, {
                onClick: () => _ctx.startCycleCount(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.enter')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["table-array", "loading"])
    ]),
    _: 1
  }))
}