
import { defineComponent, PropType } from 'vue';
import AdvancedFilters from '@/components/filters/AdvancedFilters.vue';
import FilterCriteria from '@/components/filters/FilterCriteria.vue';
import FormMultiSelect from '@/components/FormMultiSelect.vue';
import DateInput from '@/components/inputs/DateInput.vue';
import BFormCheckbox from '@/components/bootstrap-library/BFormCheckbox.vue';
import CycleCountSearchFilter from '@/dtos/filters/CycleCountSearchFilter';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import { StaticLocationType } from '@/domain/LocationType';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'cycle-count-advanced-filters',
    components: {
        BFormCheckbox,
        DateInput,
        FormMultiSelect,
        FilterCriteria,
        AdvancedFilters,
    },
    props: {
        filterDefault: Boolean,
        filterDirty: Boolean,
        modelValue: {
            type: Object as PropType<CycleCountSearchFilter>,
            default: () => new CycleCountSearchFilter(),
        },
    },
    emits: ['reset', 'submit'],
    setup(props, context) {
        const locations = masterDataStore.getInstance().locationStore.locations.filter((loc) => loc.type.staticTypeIdentifier !== StaticLocationType.BuiltIn);

        function reset() {
            context.emit('reset');
        }

        function submit() {
            context.emit('submit');
        }

        return {
            getTitleCaseTranslation,
            locations,
            reset,
            submit,
        };
    },
});
